import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {},
  container: {
    borderColor: 'gray.400',
    _dark: {
      borderColor: 'navy.900'
    }
  },
  button: {
    gap: '0.5rem',
    p: '0.5rem 0',
    _hover: {
      background: 'gray.200'
    },
    _expanded: {
      color: 'teal.700'
    },
    _dark: {
      _hover: {
        background: 'navy.900'
      },
      _expanded: {
        color: 'zest.700'
      }
    }
  }
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
