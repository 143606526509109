import { Text, Flex, Heading, SimpleGrid, Skeleton } from '@chakra-ui/react';

import style from './OrgsDashboard.module.css';
import panelStyle from '../components/CardPanel/CardPanel.module.css';

import { useEffect, useState } from 'react';
import { useColorModeValue, useColorMode } from '@chakra-ui/react';
import useAxios from '../utils/useAxios';

import SpeedGauge from '../components/SpeedGauge/SpeedGauge';
import ActivityMeter from '../components/ActivityMeter/ActivityMeter';

import {
  Clock,
  CloudOff,
  Construction,
  Grid2X2,
  Hand,
  NotebookText,
  PowerOff,
  TriangleAlert
} from 'lucide-react';
import ShelfIcon from '../components/ShelfIcon';

const GetColorFromFillingRate = (fillingRate: number) => {
  const ok_color = 'var(--chakra-colors-green-300)';
  const warning_color = 'var(--chakra-colors-yellow-300)';
  const critical_color = 'var(--chakra-colors-red-300)';
  if (fillingRate > 0.99) {
    return critical_color;
  } else if (fillingRate > 0.95) {
    return warning_color;
  } else {
    return ok_color;
  }
};

const SystemIsDown = (organization: any) => {
  if (
    organization.stub_connected === false ||
    organization.system_status === 'STP' ||
    organization.system_status === 'DSTP' ||
    organization.system_status === 'ALT' ||
    organization.system_status === 'SVC'
  ) {
    return true;
  }
  return false;
};

const SystemInServiceMode = (organization: any) => {
  if (organization.system_status === 'SVC') {
    return true;
  }
  return false;
};

const OrganizationOverview = ({ organization }: any) => {
  return (
    <SimpleGrid
      className={`${panelStyle.panel} ${style.org} ${SystemIsDown(organization) ? style.statusDisconnected : ''} ${SystemInServiceMode(organization) ? style.statusServiceMode : ''}`}
    >
      <Flex alignItems={'center'}>
        <Heading
          color={useColorModeValue('navy.700', 'white')}
          lineHeight={1}
          fontWeight={500}
          fontSize={'2rem'}
          letterSpacing={'-0.04em'}
          flexGrow={1}
          className={style.orgName}
        >
          {organization['name']}
        </Heading>

        <div className={style.orgStatus}>
          {organization.stub_connected === false ? (
            <>
              <CloudOff
                className={style.statusIcon}
                size={24}
                color="var(--chakra-colors-red-100)"
              />
            </>
          ) : organization.system_status === 'SVC' ? (
            <>
              <Construction className={style.statusIcon} size={20} />
              <Text
                color="var(--chakra-colors-yellow-100)"
                fontFamily={'Akkurat Mono'}
                fontSize={'12px'}
                lineHeight={1}
              >
                SVC
              </Text>
            </>
          ) : organization.system_status === 'ALT' ? (
            <>
              <TriangleAlert
                className={style.statusIcon}
                size={20}
                color="var(--chakra-colors-red-100)"
              />
              <Text
                fontFamily={'Akkurat Mono'}
                fontSize={'12px'}
                color="var(--chakra-colors-red-100)"
                lineHeight={1}
              >
                ALT
              </Text>
            </>
          ) : organization.system_status === 'STP' ||
            organization.system_status === 'DSTP' ? (
            <>
              <PowerOff
                className={style.statusIcon}
                size={18}
                color="var(--chakra-colors-red-100)"
              />
              <Text
                fontFamily={'Akkurat Mono'}
                fontSize={'12px'}
                color="var(--chakra-colors-red-100)"
                lineHeight={1}
              >
                STP
              </Text>
            </>
          ) : (
            ''
          )}
        </div>
      </Flex>
      <ActivityMeter
        data={organization.activities}
        status={organization.system_status}
      />

      <SimpleGrid
        flexGrow={0}
        gridTemplateColumns={'auto auto 1fr'}
        gridTemplateRows={'1fr 1fr'}
        gap={1}
      >
        {organization.stub_connected !== null ? (
          <Flex className={style.metric}>
            <Grid2X2 size={18} className={style.metricIcon} />
            <Text
              className={style.metricLabel}
              style={
                {
                  '--_color': GetColorFromFillingRate(
                    organization.used_compartments_ratio
                  )
                } as React.CSSProperties
              }
            >
              {(organization.used_compartments_ratio * 100).toFixed(1) + '%'}
            </Text>
          </Flex>
        ) : (
          <Flex className={style.metric}>
            <ShelfIcon size={18} color={'var(--chakra-colors-navy-400'} />
            <Text className={style.metricLabel}>{organization.shelves}</Text>
          </Flex>
        )}

        <Flex className={style.metric}>
          <Clock size={18} className={style.metricIcon} />
          <Text className={style.metricLabel}>
            {organization.tasks_completed_last_hour}
          </Text>
        </Flex>

        <Flex className={style.metric}>
          <NotebookText size={18} className={style.metricIcon} />
          <Text className={style.metricLabel}>
            {organization.orderlines_total}
          </Text>
        </Flex>

        <Flex className={style.metric}>
          <Hand size={18} className={style.metricIcon} />
          <Text className={style.metricLabel}>
            {organization.tasks_completed_all_day}
          </Text>
        </Flex>

        <SpeedGauge
          speed={organization.avg_orderlines_per_hour}
          max_speed="200"
          disabled={
            organization.stub_connected === false ||
            (organization.system_status !== 'RUN' &&
              organization.system_status !== null)
          }
        />
      </SimpleGrid>
    </SimpleGrid>
  );
};

const OrgsDashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useAxios();
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('dark');
    const fetchData = async () => {
      try {
        const response = await api.get('/ops/summary');
        setOrganizations(response.data.organizations);
        setLoading(false);
      } catch (error) {
        console.log('error: ' + error);
        setOrganizations([]);
        setLoading(false);
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 30000);
    return () => clearInterval(id);
  }, []);

  return (
    <Skeleton isLoaded={!loading}>
      <SimpleGrid
        gridTemplateRows={'1fr auto'}
        bgColor={useColorModeValue('gray.300', 'navy.1100')}
        minHeight={'100vh'}
        p={'0.25rem'}
        gap="0.25rem"
      >
        <SimpleGrid
          gap={'0.25rem'}
          placeContent={'start'}
          templateColumns="repeat(auto-fit, minmax(max(100% / 10 - 0.25rem, min(300px, 100%)), 1fr));"
          position={'relative'}
        >
          {organizations.map(organization => (
            <OrganizationOverview
              key={organization['id']}
              organization={organization}
            />
          ))}
        </SimpleGrid>
        <Flex className={style.legend}>
          <Flex gap={1} alignItems={'center'}>
            <Grid2X2 size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Compartments in use
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <NotebookText size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Order lines
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <Clock size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Picks last hour
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <Hand size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Picks today
            </Text>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Skeleton>
  );
};

export default OrgsDashboard;
