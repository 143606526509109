import { Text, List } from '@chakra-ui/react';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import { InformationListItem } from './DataPresentation';

const TaskInfoAccordionItem = ({ task }: any) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <AccordionIcon />
          <Text color={task.completed ? 'green.500' : 'red.500'}>
            Task number {task.task_id}
          </Text>
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <List spacing={3}>
          <InformationListItem
            label={'Completed'}
            value={task.completed ? 'Yes' : 'No'}
            tooltipText="Whether the task has been completed or not"
          />
          <InformationListItem
            label={'Cancelled'}
            value={task.cancelled ? 'Yes' : 'No'}
            tooltipText="Whether the task has been cancelled or not"
          />
          <InformationListItem
            label={'Deviated'}
            value={task.deviated_at ? task.deviated_at : 'No'}
            tooltipText="If defined, the time at which the task was deviated"
          />
          <InformationListItem
            label={'Product name'}
            value={task.product?.name}
            tooltipText="The name of the product"
          />
          <InformationListItem
            label={'SKU'}
            value={task.product?.sku}
            tooltipText="The SKU (stock-keeping unit) of the product"
          />
          <InformationListItem
            label={'Quantity'}
            value={task.quantity}
            tooltipText="The quantity of the product to be picked from the bin"
          />
          <InformationListItem
            label={'Quantity in stock'}
            value={task.product?.quantity_in_stock}
            tooltipText="The quantity of the product in stock"
          />
          <InformationListItem
            label={'Quantity allocated'}
            value={task.product?.quantity_allocated}
            tooltipText="The quantity of the product allocated to other tasks"
          />
          <InformationListItem
            label={'Quantity in orders'}
            value={task.product?.quantity_in_orders}
            tooltipText="The quantity of the product in orders"
          />
          <InformationListItem
            label={'Quantity reserved'}
            value={task.product?.quantity_reserved}
            tooltipText="The quantity of the product reserved"
          />
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TaskInfoAccordionItem;
