import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const dialogHelpers = createMultiStyleConfigHelpers(['dialog']);

export const dialogTheme = dialogHelpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      background: '#fff',
      _dark: {
        background: 'var(--chakra-colors-navy-900)'
      }
    }
  }
});
