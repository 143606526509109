import { Select, useColorModeValue } from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import useAxios from '../utils/useAxios';

const OrganizationSelect = ({
  organizations = [],
  selectedOrganization,
  setSelectedOrganization,
  maxWidth = 'max-content'
}: any) => {
  const [localOrganizations, setLocalOrganizations] = useState(organizations);

  const api = useAxios();

  useEffect(() => {
    if (!localOrganizations.length) {
      const fetchData = async () => {
        try {
          const response = await api.get('/organizations');
          setLocalOrganizations(response.data.organizations);
        } catch {
          setLocalOrganizations([]);
          // Raise error?
        }
      };
      fetchData();
    }
  }, []);

  return (
    <Select
      onChange={e => {
        setSelectedOrganization(e.target.value);
      }}
      placeholder="Select organization"
      value={selectedOrganization || ''}
      size="lg"
      variant="filled"
      color={useColorModeValue('navy.800', 'navy.300')}
      bg={useColorModeValue('gray.100', 'navy.1000')}
      borderColor={useColorModeValue('gray.800', 'navy.900')}
      borderRadius="lg"
      borderWidth="1px"
      _disabled={{ opacity: 0.5 }}
      isDisabled={localOrganizations.length === 0}
      maxWidth={maxWidth}
    >
      {localOrganizations.map((organization: any) => (
        <option key={organization.id} value={organization.id}>
          {organization.name}
        </option>
      ))}
    </Select>
  );
};

export default OrganizationSelect;
