import React from 'react';
import { Box } from '@chakra-ui/react';
import { SignInButton } from '../components/SignInButton';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
const LoginPage = () => {
  return (
    <Box>
      <SignInButton />
    </Box>
  );
};

export default LoginPage;
