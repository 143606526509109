import {
  Flex,
  Heading,
  VStack,
  SimpleGrid,
  useToast,
  Input,
  Button,
  Stack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';
import OrganizationSelect from '../components/OrganizationSelect';

const CreateOrganization = () => {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const toast = useToast();
  const axios = useAxios();

  const getOrganizations = async () => {
    await axios
      .get('/organizations')
      .then(res => {
        setOrgs(res.data);
        setLoading(false);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const create_organization = (event: any) => {
    axios
      .post('/create-organization/create-organization', {
        organization_name: event.target.name.value
      })
      .then(res => {
        toast({
          title: 'Success',
          description: 'Organization created',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        getOrganizations();
        setSelectedOrganization(res.data.organization.id);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.detail,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
    event.target.name.value = '';
  };

  const create_admin_user = (event: any) => {
    // Required fields are: first_name, last_name, email, organization_id,

    event.preventDefault();
    axios
      .post('/create-organization/create-admin-user', {
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        email: event.target.email.value,
        organization_id: selectedOrganization
      })
      .then(res => {
        toast({
          title: 'Success',
          description:
            'Admin user created. Remember to request a password reset.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.detail,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
    event.target.first_name.value = '';
    event.target.last_name.value = '';
    event.target.email.value = '';
  };

  return (
    <Navbar>
      <Heading
        color={useColorModeValue('navy.800', 'zest.400')}
        fontSize={{ base: '2xl', sm: '3xl' }}
      >
        Create organization
      </Heading>
      <VStack mt={4} spacing={4} align="stretch" maxW="50ch">
        <Flex as="form" onSubmit={create_organization} gap={4} my="4">
          <Input placeholder="Organization name" size="lg" name="name" />
          <Button size="lg" type="submit" variant={'primaryAction'}>
            Create
          </Button>
        </Flex>
        <hr />
        <Heading
          color={useColorModeValue('navy.800', 'zest.400')}
          fontSize={{ base: '2xl', sm: '3xl' }}
        >
          Create admin user
        </Heading>
        <Stack as="form" gap="1rem" maxW="50ch" onSubmit={create_admin_user}>
          {/* Required fields are: first_name, last_name, email, organization_id,  */}

          <SimpleGrid gap={2}>
            <label htmlFor="first_name">First name</label>
            <Input id="first_name" size="lg" name="first_name" />
          </SimpleGrid>
          <SimpleGrid gap={2}>
            <label htmlFor="last_name">Last name</label>
            <Input size="lg" name="last_name" id="last_name" />
          </SimpleGrid>
          <SimpleGrid gap={2}>
            <label htmlFor="email">Email</label>
            <Input size="lg" name="email" id="email" />
          </SimpleGrid>
          <Flex gap={'4'}>
            <SimpleGrid gap={2} flexGrow={1}>
              <label>Organization</label>
              <OrganizationSelect
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                maxWidth="none"
              />
            </SimpleGrid>
            <Button
              size="lg"
              type="submit"
              variant={'primaryAction'}
              alignSelf={'flex-end'}
            >
              Create
            </Button>
          </Flex>
        </Stack>
      </VStack>
    </Navbar>
  );
};

export default CreateOrganization;
