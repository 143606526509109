import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const primaryAction = defineStyle({
  background: 'var(--chakra-colors-teal-300)',
  color: 'var(--chakra-colors-navy-1200)',
  fontWeight: 'normal',
  letterSpacing: '-0.02em',
  borderRadius: '0.5rem',
  _loading: {
    background: 'var(--chakra-colors-teal-300)',
    opacity: 1,
    _hover: {
      background: 'var(--chakra-colors-teal-300)'
    }
  },
  _dark: {
    background: 'var(--chakra-colors-zest-300)',
    color: 'var(--chakra-colors-navy-1200)',
    _loading: {
      background: 'var(--chakra-colors-zest-300)',
      opacity: 1,
      _hover: {
        background: 'var(--chakra-colors-zest-300)'
      }
    }
  }
});

const secondaryAction = defineStyle({
  background: 'var(--chakra-colors-gray-700)',
  color: 'var(--chakra-colors-navy-1200)',
  fontWeight: 'normal',
  letterSpacing: '-0.02em',
  borderRadius: '0.5rem',
  _loading: {
    background: 'var(--chakra-colors-teal-300)',
    opacity: 1,
    _hover: {
      background: 'var(--chakra-colors-teal-300)'
    }
  },
  _dark: {
    background: 'var(--chakra-colors-navy-700)',
    color: 'var(--chakra-colors-navy-300)',
    _loading: {
      background: 'var(--chakra-colors-navy-700)',
      opacity: 1,
      _hover: {
        background: 'var(--chakra-colors-navy-700)'
      }
    }
  }
});

export const buttonTheme = defineStyleConfig({
  variants: { primaryAction, secondaryAction }
});
