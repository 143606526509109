import React, { ReactNode, useContext, useEffect } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Link,
  Drawer,
  Icon,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuList,
  Button
} from '@chakra-ui/react';
import { ReactText } from 'react';

import AppContext from '../context/AppContext';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import {
  CircleGauge,
  House,
  LibraryBig,
  LucideIcon,
  Medal,
  MenuIcon,
  Percent,
  PocketKnife,
  ScrollText,
  Truck,
  User2Icon
} from 'lucide-react';

interface LinkItemProps {
  name: string;
  href: string;
  icon: LucideIcon;
}
const baseURL = process.env.REACT_APP_API_URL;

const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', href: '/', icon: House },
  { name: 'Scoreboard', href: '/picks', icon: Medal },
  { name: 'Dashboard', href: '/orgs', icon: CircleGauge },
  {
    name: 'Webhook logs',
    href: '/webhooklogs?status=failed+-sent&focus=status',
    icon: ScrollText
  },
  { name: 'Organization info', href: 'organization', icon: LibraryBig },
  { name: 'Orders', href: 'orders', icon: Truck },
  { name: 'Filling degree', href: 'filling', icon: Percent },
  { name: 'RAW', href: `${baseURL}/raw`, icon: PocketKnife }
];

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: React.ChangeEvent<HTMLInputElement>) => {
    console.error(e);
  });
}

export default function Navbar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="6">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="0.375s ease"
      bg={useColorModeValue('gray.300', 'navy.1100')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.600', 'navy.1000')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text
          color={useColorModeValue('navy.800', 'zest.400')}
          fontSize="2xl"
          fontFamily="Akkurat"
          fontWeight="bold"
        >
          Backoffice
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem key={link.name} icon={link.icon} href={link.href}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: LucideIcon;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  const ItemHoverColor = 'navy.1200';
  const ItemColor = useColorModeValue('navy.1000', 'navy.200');
  return (
    <Link
      href={href}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      color={ItemColor}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: useColorModeValue('teal.100', 'zestBright.300'),
          color: ItemHoverColor
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="24"
            strokeWidth={1.5}
            _groupHover={{
              color: ItemHoverColor
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { instance, accounts } = useMsal();
  const { user } = useContext(AppContext);
  const name = accounts[0] && accounts[0].name;

  const navigate = useNavigate();

  useEffect(() => {
    // Check if admin or user is in user.roles, if not navigate to not-authorized
    if (
      user &&
      user.roles &&
      user.roles.length > 0 &&
      !user.roles!.includes('admin') &&
      !user.roles!.includes('user')
    ) {
      navigate('/not-authorized');
    }
  }, []);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.600', 'navy.1000')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        color={useColorModeValue('navy.800', 'navy.300')}
        aria-label="open menu"
        icon={<MenuIcon />}
      />

      <Text
        color={useColorModeValue('navy.800', 'zest.400')}
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="Akkurat"
        fontWeight="bold"
      >
        Backoffice
      </Text>

      <Flex gap="0.25rem">
        <ColorModeSwitcher />
        <Menu>
          <MenuButton
            color={useColorModeValue('navy.800', 'navy.300')}
            as={IconButton}
            icon={<User2Icon />}
            variant="ghost"
            aria-label="Sign out"
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          />
          <MenuList
            bg={useColorModeValue('gray.100', 'navy.900')}
            borderColor={useColorModeValue('gray.500', 'navy.800')}
            p={'1rem'}
          >
            <Text color={useColorModeValue('', 'navy.100')}>{name}</Text>
            <Text
              fontSize={'0.75rem'}
              color={useColorModeValue('', 'navy.500')}
              letterSpacing="0.06em"
              textTransform={'uppercase'}
              fontWeight={'bold'}
            >
              {user?.roles.map((role: string, index: any) => {
                if (index > 0) return '/' + role;
                return role;
              })}
            </Text>
            <Button
              variant={'primaryAction'}
              onClick={() => handleLogout(instance)}
              width={'100%'}
              mt={'1.5rem'}
            >
              Sign out
            </Button>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
