import { useState, useEffect } from 'react';

import {
  Box,
  Heading,
  Text,
  Spinner,
  Container,
  Stack,
  List,
  SimpleGrid,
  Divider,
  Accordion
} from '@chakra-ui/react';

import { useColorModeValue } from '@chakra-ui/react';

import useAxios from '../utils/useAxios';
import TaskInfoAccordionItem from '../components/TaskInfo';
import { InformationListItem } from './DataPresentation';
import OrderLineItemAccordionItem from './OrderLineItemAccordionItem';
import CardPanel from './CardPanel/CardPanel';

const OrderDetails = ({ order }: any) => {
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Colors
  const secondaryColor = useColorModeValue('navy.800', 'navy.300');
  const subHeaderColor = useColorModeValue('navy.800', 'gray.100');
  const subSubHeaderColor = useColorModeValue('navy.800', 'gray.400');

  const axios = useAxios();

  useEffect(() => {
    const getOrderDetails = async () => {
      setIsLoading(true);
      const response = await axios.get(`/orders/extended/${order}`);
      setOrderDetails(response.data);
      console.log(response.data);
      setIsLoading(false);
    };
    if (order) {
      getOrderDetails();
    }
  }, [order]);

  return (
    <Container maxW="100%" padding={0}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack spacing={{ base: 4 }} direction={'column'}>
          <Text color={subHeaderColor} fontSize={'2xl'} fontWeight={'400'}>
            Order name: {orderDetails?.order.order_name}
          </Text>

          {orderDetails !== null && (
            <SimpleGrid minChildWidth={{ base: '100%', md: '40%' }} spacing={4}>
              <CardPanel heading="Information">
                <List spacing={2}>
                  <InformationListItem
                    label="Shop"
                    value={orderDetails.shop?.name}
                    tooltipText="The shop where the order was placed"
                  />
                  <InformationListItem
                    label="Organization"
                    value={orderDetails.organization?.name}
                    tooltipText="The organization the order was placed in"
                  />
                  <InformationListItem
                    label="Status"
                    value={orderDetails.order?.status}
                    tooltipText="The status of the order in PIO"
                  />
                  <InformationListItem
                    label="Has deviations"
                    value={
                      orderDetails.deviations?.length > 0
                        ? orderDetails.deviations?.map(
                            (deviation: any) => deviation.reason
                          )
                        : 'No'
                    }
                    tooltipText="If defined, the order has deviations"
                  />
                  <InformationListItem
                    label="Is unfullfillable"
                    value={orderDetails.order?.is_unfulfillable ? 'Yes' : 'No'}
                    tooltipText="The order is unfullfillable"
                  />
                  <InformationListItem
                    label="Is partially fullfillable"
                    value={
                      orderDetails.order?.is_partially_fulfillable
                        ? 'Yes'
                        : 'No'
                    }
                    tooltipText="The order is partially fullfillable"
                  />
                  <InformationListItem
                    label="Created at"
                    value={orderDetails.order?.created_at}
                    opacity="75%"
                    tooltipText="The date and time the order was created"
                  />
                  <InformationListItem
                    label="Updated at"
                    value={orderDetails.order?.updated_at}
                    opacity="75%"
                    tooltipText="The date and time the order was last updated"
                  />
                  <InformationListItem
                    label="Cancelled at"
                    value={orderDetails.order?.cancelled_at}
                    opacity="75%"
                    tooltipText="If defined, the order was cancelled"
                  />
                  <InformationListItem
                    label="PIO order id"
                    value={orderDetails.order?.id}
                    opacity="75%"
                    tooltipText="DEBUG: The id of the order in PIO"
                  />
                  <InformationListItem
                    label="External order id"
                    value={orderDetails.order?.ext_order_id}
                    opacity="75%"
                    tooltipText="DEBUG: The id of the order in the external system"
                  />
                  <InformationListItem
                    label="Tags"
                    value={orderDetails.tags}
                    opacity="75%"
                    tooltipText="Tags set on the order"
                  />
                </List>
              </CardPanel>
              <CardPanel heading="Fulfillments">
                <Stack spacing={4}>
                  {orderDetails.fulfillments?.map((fulfillment: any) => (
                    <Box key={fulfillment.id}>
                      <List spacing={2} pb={4}>
                        <Heading
                          as="h3"
                          size={'md'}
                          fontWeight={'600'}
                          color={subSubHeaderColor}
                        >
                          {fulfillment.name}
                        </Heading>
                        <InformationListItem
                          label="Status"
                          value={fulfillment.status}
                          tooltipText="The status of the fulfillment"
                          style={{ textTransform: 'capitalize' }}
                        />
                        <InformationListItem
                          label="Deviations"
                          value={fulfillment.deviated_at ? 'Yes' : 'No'}
                          tooltipText="If the fulfillment has deviations"
                        />
                        <InformationListItem
                          label="ID"
                          value={fulfillment.id}
                          tooltipText="DEBUG: The id of the fulfillment in PIO"
                        />
                      </List>
                      <Divider />
                    </Box>
                  ))}
                </Stack>
              </CardPanel>
              <CardPanel heading="Shipments">
                {orderDetails.shipments?.map((shipment: any) => (
                  <Box key={shipment.id}>
                    <Heading
                      as={'h5'}
                      size={'md'}
                      fontWeight={'300'}
                      pb="1"
                      color={subSubHeaderColor}
                    >
                      Shipment number {shipment.id}
                    </Heading>
                    <Divider maxW={'75%'} />
                    <List spacing={2} py={'3'}>
                      <InformationListItem
                        label="Shipment status"
                        value={shipment.status}
                        tooltipText="The status of the shipment"
                      />
                      <InformationListItem
                        label="Ext. shipment status"
                        value={shipment.ext_status}
                        tooltipText="The status of the shipment at the shipping provider (i.e. DHL)"
                      />
                      <InformationListItem
                        label="Error description"
                        value={shipment.error_description}
                        tooltipText="If the shipment has an error, this is the description of the error"
                      />
                      <InformationListItem
                        label="Shipment id"
                        value={shipment.id}
                        opacity="75%"
                        tooltipText="DEBUG: The id of the shipment in PIO"
                      />
                      <InformationListItem
                        label="Ext. shipment id"
                        value={shipment.ext_shipment_id}
                        opacity="75%"
                        tooltipText="DEBUG: The id of the shipment at the shipping provider (i.e. DHL)"
                      />
                    </List>
                  </Box>
                ))}
              </CardPanel>
              <CardPanel heading="Shipping provider info">
                {orderDetails.shipment_orders?.map(
                  (shipment_order_line: any) => (
                    <Box key={shipment_order_line.id}>
                      <Heading
                        as={'h5'}
                        size={'md'}
                        fontWeight={'300'}
                        pb="1"
                        color={subSubHeaderColor}
                      >
                        Shipment order number {shipment_order_line.id}
                      </Heading>
                      <Divider maxW={'75%'} />
                      <List spacing={2} pt={'3'}>
                        <InformationListItem
                          label="Order name"
                          value={shipment_order_line.shipment_order_name}
                          tooltipText="The name of the shipment order"
                        />
                        <InformationListItem
                          label="Quantity"
                          value={shipment_order_line.quantity}
                          tooltipText="The quantity of items in the shipment order (?)"
                        />
                        <InformationListItem
                          label="Ext. status"
                          value={shipment_order_line.ext_status}
                          tooltipText="The status of the shipment order at the shipping provider (i.e. DHL)"
                        />
                        <InformationListItem
                          label="Shipping rate"
                          value={orderDetails.shipping_rate_name}
                          tooltipText="The shipping rate of the shipment order"
                        />
                        <InformationListItem
                          label="Shipment order id"
                          value={shipment_order_line.id}
                          opacity="75%"
                          tooltipText="DEBUG: The id of the shipment order in PIO"
                        />
                        <InformationListItem
                          label="Ext. shipment order id"
                          value={shipment_order_line.ext_shipment_order_id}
                          opacity="75%"
                          tooltipText="DEBUG: The id of the shipment order at the shipping provider (i.e. DHL)"
                        />
                      </List>
                    </Box>
                  )
                )}
              </CardPanel>
              <CardPanel heading="Tasks">
                <Accordion allowMultiple>
                  {orderDetails?.tasks?.map((task: any) => (
                    <TaskInfoAccordionItem key={task.task_id} task={task} />
                  ))}
                </Accordion>
              </CardPanel>
              <CardPanel heading="Order line items">
                <Accordion allowMultiple>
                  {orderDetails?.order_line_items?.map(
                    (order_line_item: any) => (
                      <OrderLineItemAccordionItem
                        key={order_line_item.id}
                        order_line_item={order_line_item}
                        order_details={orderDetails}
                      />
                    )
                  )}
                </Accordion>
              </CardPanel>
            </SimpleGrid>
          )}
        </Stack>
      )}
    </Container>
  );
};

export default OrderDetails;
