import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import {
  Button,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Heading
} from '@chakra-ui/react';

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.300', 'navy.1100')}
    >
      <SimpleGrid
        rounded={'lg'}
        bg={useColorModeValue('gray.100', 'navy.1000')}
        boxShadow={'lg'}
        p={8}
      >
        <Heading
          fontSize={'2xl'}
          mb={'6'}
          color={useColorModeValue('navy.800', 'navy.300')}
        >
          Welcome to the Backoffice
        </Heading>

        <Button
          variant={'primaryAction'}
          size="lg"
          onClick={() => handleLogin(instance)}
        >
          Sign in with SSO
        </Button>
      </SimpleGrid>
    </Flex>
  );
};
