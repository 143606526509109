import { List, Button } from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import { InformationListItem } from './DataPresentation';

const ShopAccordion = ({ shop, onExport }: any) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <AccordionIcon />
          {shop.name}
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <List spacing={2}>
          <InformationListItem
            label={'Created at'}
            value={shop.created_at.split('T')[0]}
            tooltipText="The time at which the shop was created"
          />
          <InformationListItem
            label={'Allow partial fulfilments'}
            value={
              shop.shop_settings?.allow_partial_fulfillments ? 'Yes' : 'No'
            }
            tooltipText="Whether the shop allows partial fulfilments"
          />
          <InformationListItem
            label={'Shop id'}
            value={shop.id}
            tooltipText="The id of the shop"
          />
          <Button
            onClick={() => onExport(shop.name, shop.id)}
            variant={'primaryAction'}
            size="md"
          >
            Get inventory report
            <ExternalLinkIcon ml="8px" />
          </Button>
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ShopAccordion;
