import { useState, useCallback, useEffect } from 'react';
import React from 'react';
import {
  Heading,
  VStack,
  Box,
  useColorModeValue,
  Input,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon,
  Flex,
  Stack,
  Skeleton
} from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import useAxios from '../utils/useAxios';
import style from './WebhookLog.module.css';
import {
  parse as parse_duration,
  toSeconds as durationToSeconds
} from 'iso8601-duration';
import { Info } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import CardPanel from '../components/CardPanel/CardPanel';

interface Hook {
  id: Number;
  event: string;
  url: string;
}
interface Shop {
  id: Number;
  name: string;
}
interface Organization {
  id: Number;
  name: string;
}

interface ExternalWebhooksOutboxLog {
  created_ats: string[];
  elapsed_times: string[];
  hook: Hook;
  log_ids: number[];
  organization: Organization;
  outbox_id: number;
  request_bodies: (string | null)[];
  response_bodies: (string | null)[];
  response_status_codes: (number | null)[];
  shop: Shop;
  statuses: string[];
  status_reasons: (number | null)[];
}

type ErrorDetails = {
  detail: {
    loc: string[];
    msg: string;
    [key: string]: any;
  }[];
};

function createErrorMessage(obj: ErrorDetails): string[] {
  return obj.detail.map(({ loc, msg }) => {
    if (!loc || !msg) {
      return 'Invalid error detail';
    }
    return `${loc[1]} -> ${msg}`;
  });
}

const JsonPopup: React.FC<{ jsonString: string; label: string }> = ({
  jsonString,
  label
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let parsedJson: string;
  try {
    parsedJson = JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    parsedJson = 'Invalid JSON';
  }

  return (
    <>
      <Text
        color={useColorModeValue('teal.500', 'zest.500')}
        cursor="pointer"
        lineHeight="1"
        _hover={{ textDecoration: 'underline' }}
        onClick={onOpen}
      >
        View
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg={useColorModeValue('gray.200', 'navy.900')}>
          <ModalHeader color={useColorModeValue('navy.800', 'navy.300')}>
            {label} JSON Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              whiteSpace="pre-wrap"
              fontSize="md"
              p={4}
              bg={useColorModeValue('gray.100', 'navy.800')}
              color={useColorModeValue('navy.800', 'navy.300')}
              borderRadius="md"
              boxShadow="md"
              maxHeight="500px"
              overflowY="auto"
            >
              {parsedJson}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={'primaryAction'}
              mr={3}
              onClick={onClose}
              _hover={{}}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const statusReasonLookup: { [key: number]: string } = {
  1: 'Undefined error.',
  2: 'The request timed out.',
  3: 'Network error.',
  4: 'Too many redirects.',
  5: 'Invalid URL provided.',
  6: 'Invalid status code'
};

const LogsTable = React.memo(
  ({ logGroups }: { logGroups: ExternalWebhooksOutboxLog[] }) => {
    return (
      <Flex direction={'column'} gap={'1rem'}>
        {logGroups.length > 0 ? (
          logGroups.map((group, index) => (
            <CardPanel key={index}>
              <dl className={style.logMeta}>
                <dt>Outbox ID</dt>
                <dd>{group.outbox_id || 'Unknown'}</dd>

                <dt>Organization</dt>
                <dd>{group.organization.name || 'Unknown'}</dd>

                <dt>Shop</dt>
                <dd>{group.shop.name || 'Unknown'}</dd>

                <dt>Topic</dt>
                <dd>{group.hook.event || 'Unknown'}</dd>

                <dt>URL</dt>
                <dd>{group.hook.url || 'Unknown'}</dd>
              </dl>
              <Table size="sm" className={style.logTable}>
                <Thead>
                  <Tr>
                    <Th>Log ID</Th>
                    <Th>Status</Th>
                    <Th>Status Reason</Th>
                    <Th>Created At</Th>
                    <Th>Elapsed Time [s]</Th>
                    <Th>Request Body</Th>
                    <Th>Response Status Code</Th>
                    <Th>Response Body</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {group.log_ids.map((logId, idx) => (
                    <Tr key={idx}>
                      <Td>{logId}</Td>
                      <Td>{group.statuses[idx]}</Td>
                      <Td>
                        {group.status_reasons &&
                        group.status_reasons[idx] !== null &&
                        group.status_reasons[idx] !== undefined
                          ? statusReasonLookup[
                              group.status_reasons[idx] as number
                            ] || ''
                          : ''}
                      </Td>
                      <Td>
                        {new Date(group.created_ats[idx]).toLocaleString()}
                      </Td>
                      <Td>
                        {durationToSeconds(
                          parse_duration(group.elapsed_times[idx])
                        ).toFixed(2)}
                      </Td>
                      <Td>
                        {group.request_bodies[idx] && (
                          <JsonPopup
                            jsonString={group.request_bodies[idx] || ''}
                            label="Request Body"
                          />
                        )}
                      </Td>
                      <Td>{group.response_status_codes[idx]}</Td>
                      <Td>
                        {group.response_bodies[idx] && (
                          <JsonPopup
                            jsonString={group.response_bodies[idx] || ''}
                            label="Response Body"
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </CardPanel>
          ))
        ) : (
          <CardPanel>No logs found</CardPanel>
        )}
      </Flex>
    );
  }
);

const LogsPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [logGroups, setLogGroups] = useState<ExternalWebhooksOutboxLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [focus] = useState(searchParams.get('focus') ?? 'organization');
  const [organization, setorganization] = useState(
    searchParams.get('organization') ?? ''
  );
  const [status, setStatus] = useState(searchParams.get('status') ?? '');
  const [requestResponseBody, setRequestResponse] = useState(
    searchParams.get('request_response_body') ?? ''
  );
  const [responseStatusCode, setResponseStatusCode] = useState(
    searchParams.get('response_status_code') ?? ''
  );

  const [fromTimestamp, setFromTimestamp] = useState(
    searchParams.get('from_timestamp') ?? new Date().toISOString().split('T')[0]
  );
  const [toTimestamp, setToTimestamp] = useState(
    searchParams.get('to_timestamp') ?? ''
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const api = useAxios();

  const buildQueryParams = () => {
    const queryParams = new URLSearchParams();
    if (organization) queryParams.append('organization', organization.trim());
    if (responseStatusCode)
      queryParams.append('response_status_code', responseStatusCode.trim());
    if (status) queryParams.append('status', status.trim());
    if (requestResponseBody)
      queryParams.append('request_response_body', requestResponseBody.trim());
    if (fromTimestamp)
      queryParams.append('from_timestamp', fromTimestamp.trim());
    if (toTimestamp) queryParams.append('to_timestamp', toTimestamp.trim());
    return queryParams;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessages([]);
    try {
      const queryParams = buildQueryParams();
      const response = await api.get(`/webhook/log?${queryParams.toString()}`);

      if (response.status >= 200 && response.status < 300) {
        const data: ExternalWebhooksOutboxLog[] =
          response.data.log_groups || [];
        setLogGroups(data);
      } else {
        setLogGroups([]); // Clear the page if response is not 2xx
        setErrorMessages([response.statusText]); // Show the response status text as a warning
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogGroups([]); // Clear the page in case of an erroor
      const { response } = error as any;
      if (response && 'data' in response) {
        setErrorMessages(createErrorMessage(response.data));
      }
    }
    setLoading(false);
  }, [api, buildQueryParams]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, []); // Removed fetchData from dependency array to prevent infinite loop

  const handleFilter = (input?: string, fetch?: boolean) => {
    const queryParams = buildQueryParams();

    // Adds the input submitted from to the query string so we know where to autofocus on reload
    if (input) queryParams.append('focus', input);

    // Force navigation with new query string to keep searches in browser history
    window.location.search = queryParams.toString();
  };

  const handleFilterButton = () => {
    const queryParams = buildQueryParams();
    window.location.search = queryParams.toString();
  };

  const textColor = useColorModeValue('navy.800', 'zest.400');

  const SkeletonProps = {
    startColor: useColorModeValue('gray.600', 'navy.600'),
    endColor: useColorModeValue('gray.200', 'navy.900'),
    height: '1rem'
  };

  return (
    <Navbar>
      <VStack spacing={6} align="stretch">
        <Heading
          as="h1"
          color={textColor}
          fontSize={{ base: '2xl', sm: '3xl' }}
        >
          Webhook logs
        </Heading>
        <Flex className={style.filters}>
          <label>
            <span>Organization name</span>
            <Input
              value={organization}
              onChange={e => setorganization(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleFilter('organization')}
              autoFocus={focus === 'organization' ? true : false}
              htmlSize={12}
            />
          </label>
          <label>
            <span>Webhook status</span>
            <Input
              value={status}
              onChange={e => setStatus(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleFilter('status')}
              autoFocus={focus === 'status' ? true : false}
              htmlSize={10}
            />
          </label>
          <label>
            <span>Body</span>
            <Input
              value={requestResponseBody}
              onChange={e => setRequestResponse(e.target.value)}
              onKeyDown={e =>
                e.key === 'Enter' && handleFilter('response_request_body')
              }
              autoFocus={focus === 'response_request_body' ? true : false}
              htmlSize={15}
            />
          </label>
          <label>
            <span>Code</span>
            <Input
              placeholder="xxx"
              value={responseStatusCode}
              onChange={e => setResponseStatusCode(e.target.value)}
              onKeyDown={e =>
                e.key === 'Enter' && handleFilter('response_status_code')
              }
              autoFocus={focus === 'response_status_code' ? true : false}
              maxLength={3}
              htmlSize={3}
            />
          </label>
          <label>
            <span>From date</span>
            <Input
              placeholder="YYYY-MM-DD"
              value={fromTimestamp}
              onChange={e => setFromTimestamp(e.target.value)}
              onKeyDown={e =>
                e.key === 'Enter' && handleFilter('from_timestamp')
              }
              autoFocus={focus === 'from_timestamp' ? true : false}
              htmlSize={10}
            />
          </label>
          <label>
            <span>To date</span>
            <Input
              placeholder="YYYY-MM-DD"
              value={toTimestamp}
              onChange={e => setToTimestamp(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleFilter('to_timestamp')}
              autoFocus={focus === 'to_timestamp' ? true : false}
              htmlSize={10}
            />
          </label>
          <Button
            flex={'0 0 auto'}
            variant={'primaryAction'}
            size={'lg'}
            onClick={handleFilterButton}
            isLoading={loading}
          >
            Search
          </Button>
        </Flex>
        {errorMessages.length > 0 && (
          <>
            {errorMessages.map((message, index) => (
              <Alert borderRadius={8} status="error" key={index}>
                <AlertIcon />
                {message}
              </Alert>
            ))}
          </>
        )}
        {loading ? (
          <CardPanel>
            <Stack>
              <Skeleton {...SkeletonProps} />
              <Skeleton {...SkeletonProps} />
              <Skeleton {...SkeletonProps} />
            </Stack>
          </CardPanel>
        ) : (
          <LogsTable logGroups={logGroups} />
        )}
      </VStack>
    </Navbar>
  );
};

export default LogsPage;
