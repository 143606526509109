import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const linkButton = defineStyle({
  border: '1px solid',
  borderColor: 'gray.600',
  borderRadius: '12px',
  color: 'navy.800',
  fontWeight: 'bold',
  padding: '0.5em 0.75em',
  textDecoration: 'none',

  _hover: {
    background: 'gray.200',
    borderColor: 'gray.800',
    textDecoration: 'none'
  },

  // let's also provide dark mode alternatives
  _dark: {
    borderColor: 'navy.900',
    color: 'zest.300',
    _hover: {
      background: 'navy.1000',
      borderColor: 'zest.300'
    }
  }
});

export const linkTheme = defineStyleConfig({
  variants: { linkButton }
});
