import { ReactNode } from 'react';
import style from './CardPanel.module.css';
import { Heading } from '@chakra-ui/react';

interface Props {
  heading?: string;
  children: ReactNode;
}

export default function CardPanel({ heading, children }: Props) {
  return (
    <div className={style.panel}>
      {heading && (
        <Heading fontSize={'xl'} className={style.heading} as="h2" mb={4}>
          {heading}
        </Heading>
      )}
      {children}
    </div>
  );
}
