import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { Heading, VStack, Input, Button, Link, Flex } from '@chakra-ui/react';

import Navbar from '../components/Navbar';
import useAxios from '../utils/useAxios';
import { DataTable } from '../components/DataTable';
import { useColorModeValue } from '@chakra-ui/react';

import OrderDetails from '../components/OrderDetail';
import OrganizationSelect from '../components/OrganizationSelect';
import CardPanel from '../components/CardPanel/CardPanel';

const OrderSearchResultTable = ({ results }: any) => {
  type SearchResult = {
    shop_name: string;
    organization_name: string;
    order_name: string;
    created_at: string;
    status: string;
    id: number;
  };

  const data: SearchResult[] = results;

  const columnHelper = createColumnHelper<SearchResult>();

  const linkColor = useColorModeValue('teal.500', 'zest.500');

  const columns = [
    columnHelper.accessor('order_name', {
      cell: info => info.getValue(),
      header: 'Order name'
    }),
    columnHelper.accessor('shop_name', {
      cell: info => info.getValue(),
      header: 'Shop name'
    }),
    columnHelper.accessor('organization_name', {
      cell: info => info.getValue(),
      header: 'Organization name'
    }),
    columnHelper.accessor('created_at', {
      cell: info => info.getValue(),
      header: 'Created at',
      meta: {
        isDate: true
      }
    }),
    columnHelper.accessor('status', {
      cell: info => info.getValue(),
      header: 'Status'
    }),
    columnHelper.accessor('id', {
      cell: id => (
        <Link
          letterSpacing="-0.02em"
          color={linkColor}
          href={`orders?order-id=${id.getValue()}`}
        >
          View order
        </Link>
      ),
      header: 'Actions'
    })
  ];

  return (
    <CardPanel>
      {data.length > 0 ? (
        <DataTable columns={columns} data={data} />
      ) : (
        'No results found'
      )}
    </CardPanel>
  );
};

const OrderSearch = ({
  setResults,
  setResultsVisible,
  setOrderViewVisible
}: any) => {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('order_name') ?? ''
  );
  const [selectedOrganization, setSelectedOrganization] = useState(
    searchParams.get('org') ?? ''
  );
  const [searching, setSearching] = useState(false);
  const api = useAxios();

  const buildQueryParams = () => {
    const queryParams = new URLSearchParams();
    if (selectedOrganization) queryParams.append('org', selectedOrganization);
    if (searchTerm) queryParams.append('order_name', searchTerm);

    return queryParams;
  };

  const handleSearch = async () => {
    setSearching(true);
    setOrderViewVisible(false);
    const response = await api.get('/orders/search', {
      params: {
        order_name: searchTerm,
        ...(selectedOrganization
          ? { organization_id: selectedOrganization }
          : {})
      }
    });
    setResults(response.data.orders);
    setResultsVisible(true);
    setSearching(false);
  };

  useEffect(() => {
    if (searchTerm && selectedOrganization) {
      handleSearch();
    }
  }, []); // Removed fetchData from dependency array to prevent infinite loop

  const handleSearchButton = () => {
    const queryParams = buildQueryParams();
    window.location.search = queryParams.toString();
  };

  return (
    <Flex gap="1rem" justifyContent={'flex-start'}>
      <OrganizationSelect
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
      />
      <Input
        type="text"
        placeholder="Order name"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && handleSearchButton()}
        autoFocus={searchTerm ? true : false}
        maxWidth={'40ch'}
        isDisabled={!selectedOrganization}
      />
      <Button
        type="submit"
        isLoading={searching}
        onClick={handleSearchButton}
        variant={'primaryAction'}
        size={'lg'}
      >
        Search
      </Button>
    </Flex>
  );
};

const Orders = () => {
  const [orderSearchResults, setOrderSearchResults] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
  const [orderSearchResultsVisible, setOrderSearchResultsVisible] =
    useState(false);
  const [orderViewVisible, setOrderViewVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get('order-id');

  useEffect(() => {
    if (orderId) {
      setOrderViewVisible(true);
      setOrderSearchResultsVisible(false);
      setSelectedOrder(+orderId);
    }
  }, [orderId]);

  return (
    <Navbar>
      <VStack spacing={6} align="stretch">
        <Heading
          as="h1"
          color={useColorModeValue('navy.800', 'zest.400')}
          fontSize={{ base: '2xl', sm: '3xl' }}
        >
          Orders
        </Heading>
        <OrderSearch
          setResults={setOrderSearchResults}
          setResultsVisible={setOrderSearchResultsVisible}
          setOrderViewVisible={setOrderViewVisible}
        />
        {orderSearchResultsVisible && (
          <OrderSearchResultTable results={orderSearchResults} />
        )}
        {orderViewVisible && <OrderDetails order={selectedOrder} />}
      </VStack>
    </Navbar>
  );
};

export default Orders;
