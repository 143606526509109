import { defineStyleConfig } from '@chakra-ui/react';
export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    background: 'var(--chakra-colors-gray-500)',
    color: 'var(--chakra-colors-navy-1200)',
    _dark: {
      background: 'var(--chakra-colors-zest-100)'
    }
  }
});
