import {
  Box,
  Text,
  Divider,
  useColorModeValue,
  List,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast
} from '@chakra-ui/react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react';

import { useState, useRef } from 'react';

import useAxios from '../utils/useAxios';

import { InformationListItem } from './DataPresentation';

const getRole = (user: any) => {
  if (user.is_admin) {
    return 'Admin';
  } else if (user.is_manager) {
    return 'Manager';
  } else {
    return 'Staff';
  }
};

const UserAccordionInfo = ({ user }: any) => {
  const [currentRole, setCurrentRole] = useState(getRole(user));
  const [newRole, setNewRole] = useState(getRole(user));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();

  const toast = useToast();
  const axios = useAxios();

  const changeUserRole = () => {
    var is_admin = false;
    var is_manager = false;
    var is_staff = false;

    if (newRole === 'Admin') {
      is_admin = true;
    } else if (newRole === 'Manager') {
      is_manager = true;
    } else {
      is_staff = true;
    }

    axios
      .post(`/db_users/${user.id}/role`, {
        is_admin: is_admin,
        is_manager: is_manager,
        is_staff: is_staff
      })
      .then(res => {
        toast({
          title: 'Success',
          description: 'User role updated',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        setCurrentRole(getRole(res.data));
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <AccordionIcon />
          <Box flex="1" textAlign="left">
            {user.first_name} {user.last_name}
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <List spacing={3}>
          <InformationListItem
            label={'Email'}
            value={user.email}
            tooltipText="The email of the user"
          />
          <InformationListItem
            label={'Current role'}
            value={getRole(user)}
            tooltipText="The user role"
          />
        </List>
        {/* Add ability to change role */}
        <Box>
          <Text
            fontSize="md"
            color={useColorModeValue('navy.800', 'navy.300')}
            mt="2"
          >
            Change role
          </Text>
          <Divider />
          <Box>
            <RadioGroup
              onChange={value => {
                setNewRole(value);
                onOpen();
              }}
              value={currentRole}
              mt="2"
              colorScheme={useColorModeValue('teal', 'zest')}
            >
              <Stack>
                <Radio value="Admin">Admin</Radio>
                <Radio value="Manager">Manager</Radio>
                <Radio value="Staff">Staff</Radio>
              </Stack>
            </RadioGroup>
          </Box>
        </Box>
      </AccordionPanel>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change user role
            </AlertDialogHeader>

            <AlertDialogBody>
              Changing {user.first_name} {user.last_name} from {currentRole} to{' '}
              <strong>{newRole}</strong>.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant={'secondaryAction'}
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant={'primaryAction'}
                onClick={() => {
                  changeUserRole();
                  onClose();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AccordionItem>
  );
};

export default UserAccordionInfo;
