import { extendTheme } from '@chakra-ui/react';

import { accordionTheme } from './themes/Accordion';
import { buttonTheme } from './themes/Button';
import { inputTheme } from './themes/Input';
import { dialogTheme } from './themes/Dialog';
import { linkTheme } from './themes/Link';
import { tooltipTheme } from './themes/Tooltip';

const theme = extendTheme({
  components: {
    Accordion: accordionTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Input: inputTheme,
    Dialog: dialogTheme,
    Tooltip: tooltipTheme
  },
  styles: {
    global: {
      body: {}
    }
  },
  fonts: {
    heading: `Akkurat, 'Metropolis', sans-serif`,
    body: `Akkurat, sans-serif`,
    mono: `'Akkurat Mono', sans-serif`
  },
  colors: {
    gray: {
      100: '#FFFFFF',
      200: '#F8F9FA',
      300: '#F1F3F5',
      400: '#ECEEF0',
      500: '#E6E8EB',
      600: '#DFE3E6',
      700: '#D7DBDF',
      800: '#C1C8CD',
      900: '#889096',
      1000: '#7E868C',
      1100: '#687076',
      1200: '#11181C'
    },
    navy: {
      100: '#EBEDEF',
      200: '#E9EBED',
      300: '#D3D7DC',
      400: '#BDC3CA',
      500: '#A7AFB9',
      600: '#79818D',
      700: '#576270',
      800: '#445160',
      900: '#2F3E4E',
      1000: '#18283A',
      1100: '#111E2D',
      1200: '#000A19'
    },
    zest: {
      100: '#FCFEF2',
      200: '#F6FBD8',
      300: '#F0F9BE',
      400: '#E6EFB4',
      500: '#DCE5AA',
      600: '#DCE5AA',
      700: '#C8D196',
      800: '#B4BD82',
      900: '#A0A96E',
      1000: '#828B50',
      1100: '#646D32',
      1200: '#323B00'
    },
    zestBright: {
      200: '#F8FFD1',
      300: '#F4FFBD',
      400: '#EDFAA3'
    },
    teal: {
      100: '#A5E5E5',
      200: '#46D0D0',
      300: '#1DBFBF',
      400: '#19B5B5',
      500: '#0AABAB',
      600: '#14A1A1',
      700: '#148D8D',
      800: '#147979',
      900: '#1E6F6F',
      1000: '#0A5B5B',
      1100: '#0A4D4D',
      1200: '#0A3D3D'
    }
  }
});

export default theme;
