import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const input = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'gray.500',
    background: 'gray.100',
    borderRadius: '0.5rem',

    _placeholder: {
      color: 'navy.400',
      fontStyle: 'italic',
      fontSize: '90%'
    },

    _focusVisible: {
      outline: '1px solid',
      outlineColor: 'teal.500',
      outlineOffset: '0',
      borderColor: 'teal.500'
    },
    _dark: {
      borderColor: 'navy.900',
      background: 'navy.1000',

      _focusVisible: {
        outlineColor: 'zest.500',
        borderColor: 'zest.500'
      },
      _placeholder: {
        color: 'navy.800'
      }
    }
  },
  addon: {
    border: '1px solid',
    borderColor: 'gray.200',
    background: 'gray.200',
    borderRadius: 'full',
    color: 'gray.500',

    _dark: {
      borderColor: 'gray.600',
      background: 'gray.600',
      color: 'gray.400'
    }
  }
});

export const inputTheme = defineMultiStyleConfig({
  variants: { input },
  defaultProps: {
    size: 'lg',
    variant: 'input'
  }
});
